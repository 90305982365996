import React, { useState, useEffect } from 'react';
import './MenuBookDesk.css';

const API_URL_PRODUCTS = "/api/products";
const API_URL_CATEGORIES = "/api/categories";

function MenuBookDesk() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("all");

  useEffect(() => {
    async function fetchMenu() {
      try {
        const responseProducts = await fetch(API_URL_PRODUCTS);
        const dataProducts = await responseProducts.json();
        setProducts(dataProducts);

        const responseCategories = await fetch(API_URL_CATEGORIES);
        const dataCategories = await responseCategories.json();
        setCategories(dataCategories);
      } catch (error) {
        console.error("Erro ao buscar dados do menu", error);
      }
    }
    fetchMenu();
  }, []);

  const filteredProducts = selectedCategory === "all"
    ? products
    : products.filter(product => product.category === selectedCategory);

  const nextPage = () => {
    if (currentPage < filteredProducts.length - 9) {
      setCurrentPage(currentPage + 9);
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Rola para o topo
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 9);
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Rola para o topo
    }
  };

  const goToPage = (page) => {
    setCurrentPage(page * 9);
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Rola para o topo
  };

  const productsToShow = filteredProducts.slice(currentPage, currentPage + 9);

  // Calcula o total de páginas
  const totalPages = Math.ceil(filteredProducts.length / 9);

  // Gera os números de páginas para exibição
  const generatePageNumbers = (currentPage, totalPages) => {
    const pageNumbers = [];
    const maxPages = 4;
    const start = Math.max(0, Math.min(currentPage - Math.floor(maxPages / 2), totalPages - maxPages));
    for (let i = start; i < Math.min(start + maxPages, totalPages); i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  const pageNumbers = generatePageNumbers(currentPage, totalPages);

  return (
    <div className="page-container-desk">
      <div className="menu-book-desk">
        <div className="category-filter-desk">
          <button
            className={`category-button-desk ${selectedCategory === "all" ? 'active' : ''}`}
            onClick={() => {
              setSelectedCategory("all");
              window.scrollTo({ top: 0, behavior: 'smooth' }); // Rola para o topo
            }}
          >
            Todos
          </button>
          {categories.map(category => (
            <button
              key={category.id}
              className={`category-button-desk ${selectedCategory === category.name ? 'active' : ''}`}
              onClick={() => {
                setSelectedCategory(category.name);
                window.scrollTo({ top: 0, behavior: 'smooth' }); // Rola para o topo
              }}
            >
              {category.name}
            </button>
          ))}
        </div>
        <div className="book-container-desk">
          <div className="product-grid-desk">
            {productsToShow.map((product, index) => (
              <div key={index} className="product-card-desk">
                <div className="product-image-container-desk">
                  <img
                    className="product-image-desk"
                    src={`/api/uploads/${product.image_id}.png`} /* Ajuste conforme necessário */
                    alt={product.name}
                  />
                </div>
                <div className="product-details-desk">
                  <h3>{product.name}</h3>
                  <p>{product.description}</p>
                  <h2>R$ {product.price.toFixed(2)}</h2>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="pagination-controls">
        <button onClick={prevPage} disabled={currentPage === 0}>Voltar</button>
        <div className="page-numbers">
          {pageNumbers.map(number => (
            <span
              key={number}
              className={number === Math.floor(currentPage / 9) ? 'active' : ''}
              onClick={() => goToPage(number)}
            >
              {number + 1}
            </span>
          ))}
        </div>
        <button onClick={nextPage} disabled={currentPage >= filteredProducts.length - 9}>Próxima</button>
      </div>
    </div>
  );
}

export default MenuBookDesk;
