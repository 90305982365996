// App.js
import React from 'react';
import ResponsiveLayout from './ResponsiveLayout'; // Importa o ResponsiveLayout
import './App.css'; // Importando estilos específicos para o App, se necessário

function App() {
  return (
    <div className="App">
      <ResponsiveLayout />
    </div>
  );
}

export default App;
