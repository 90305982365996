// ResponsiveLayout.js
import React, { useState, useEffect } from 'react';
import MenuBook from './MenuBook'; // Componente para Mobile
import MenuBookDesk from './MenuBookDesk'; // Componente para Desktop
import './ResponsiveLayout.css'; // Estilos para o layout

function ResponsiveLayout() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="responsive-layout">
      <header className="header">
        <h1>
          <img
            className="logo-image"
            src={`/api/uploads/logo.png`} /* Ajuste conforme necessário */
            alt="logo"
          />
          Shopie Brasil
        </h1>
      </header>
      {/* Renderiza o layout diretamente, sem a div extra */}
      {isDesktop ? <MenuBookDesk /> : <MenuBook />}
    </div>
  );
}

export default ResponsiveLayout;
