import React, { useState, useEffect, useRef } from 'react';
import './MenuBook.css';

const API_URL_PRODUCTS = "/api/products";
const API_URL_CATEGORIES = "/api/categories";

function MenuBook() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const productContainerRef = useRef(null);  // Para rolagem horizontal manual

  useEffect(() => {
    async function fetchMenu() {
      try {
        const responseProducts = await fetch(API_URL_PRODUCTS);
        const dataProducts = await responseProducts.json();
        setProducts(dataProducts);

        const responseCategories = await fetch(API_URL_CATEGORIES);
        const dataCategories = await responseCategories.json();
        setCategories(dataCategories);
      } catch (error) {
        console.error("Erro ao buscar dados do menu", error);
      }
    }
    fetchMenu();
  }, []);

  const filteredProducts = selectedCategory === "all"
    ? products
    : products.filter(product => product.category === selectedCategory);

  return (
    <div className="page-container">
      <div className="menu-book">
        <div className="category-filter">
          <button
            className={`category-button ${selectedCategory === "all" ? 'active' : ''}`}
            onClick={() => setSelectedCategory("all")}
          >
            Todos
          </button>
          {categories.map(category => (
            <button
              key={category.id}
              className={`category-button ${selectedCategory === category.name ? 'active' : ''}`}
              onClick={() => setSelectedCategory(category.name)}
            >
              {category.name}
            </button>
          ))}
        </div>
        <div className="book-container">
          <div className="product-grid" ref={productContainerRef}>
            {filteredProducts.map((product, index) => (
              <div key={index} className="product-card">
                <div className="product-image-container">
                  <img
                    className="product-image"
                    src={`/api/uploads/${product.image_id}.png`} /* Ajuste conforme necessário */
                    alt={product.name}
                  />
                </div>
                <div className="product-details">
                  <h3>{product.name}</h3>
                  <p>{product.description}</p>
                  <h2>R$ {product.price.toFixed(2)}</h2>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MenuBook;
